<template>
  <b-card>
    <div
      class="text-center d-flex flex-column justify-content-center align-items-center"
    >
      <h1 class="mt-5 mb-3">
        {{ $t('maxWaitTime') }}
      </h1>
      <div
        v-if="!loading"
        class="mb-3"
      >
        <b-button
          v-if="timer"
          variant="outline-success"
        ><feather-icon
          size="16"
          class="mr-1"
          icon="ClockIcon"
        />{{
          formattedTimer
        }}</b-button>
        <b-button
          v-else-if="!timer"
          variant="outline-danger"
        ><feather-icon
          size="16"
          class="mr-1"
          icon="ClockIcon"
        />{{
          $t('expired')
        }}</b-button>
      </div>
      <b-img
        width="300"
        :src="require('@/assets/images/yoconstruyo/aby3.gif')"
      />
      <h1 class="mt-5 mb-1">
        {{ $t('workingOnYourQuote') }}
      </h1>
      <h3 class="mt-0 mb-1 font-italic">
        {{ $t('workingSchedule') }}
      </h3>
      <b-button
        class="my-5"
        variant="primary"
        @click="$router.go(-1)"
      >
        {{ $t('return') }}
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  data() {
    return {
      timer: null,
      loading: false,
    }
  },

  computed: {
    ...mapGetters('quotes', ['quote']),

    formattedTimer() {
      return moment.utc(this.timer).format('HH:mm:ss')
    },
  },
  watch: {
    timer: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timer = moment
              .duration(this.timer)
              .subtract(1, 'seconds')
              .asMilliseconds()
          }, 1000)
        } else if (value < 0) {
          this.timer = null
        }
      },
      immediate: true,
    },
  },

  created() {
    this.loading = true
    this.fetchQuote(this.$route.params.quoteId).then(response => {
      const remainingTime = moment(response.auction_expires_at).diff(moment())
      this.timer = remainingTime
      this.loading = false
    })
  },

  methods: {
    ...mapActions('quotes', ['fetchQuote', 'fetchSupplierBranchQuoteItems']),
  },
}
</script>

<style lang="scss" scoped></style>
